.responsive-video {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 0;
  height: 0;
  overflow: hidden;
  background: linear-gradient(
          to right,
          var(--ion-color-blue2),
          var(--ion-color-turquoise)
  );
}

.responsive-video iframe {
  position: absolute;
  top: 5%;
  left: 5%;
  width: 90%;
  height: 90%;
  border: none;
}

h3 p.breadcrumb {
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 2px;
}

ul li {
  list-style: none;
}

ul li a {
  color: var(--ion-color-black2);
  font-weight: bold;
  text-decoration: none;
}

ul li ion-icon {
  color: var(--ion-color-blue2);
}

ul li span, ul li a {
  vertical-align: text-bottom;
}

