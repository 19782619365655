.leaflet-container {
  height: 100%;
  width: 100%;
}

.leaflet-popup-content {
  font-family: "AppFont", sans-serif;
  margin: 2px;
  color: white;
}

.leaflet-popup-content p {
  margin: 0;
}

.popup-header h3 {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 20px;
  margin-top:2px;
}

.leaflet-popup ion-list {
  background: linear-gradient(
          to right,
          var(--ion-color-blue2),
          var(--ion-color-turquoise)
  );
  padding: 4px;
}

.leaflet-popup-content-wrapper {
  border-radius: 0;
}