/* Ionic Variables and Theming. For more information, please see
// https://beta.ionicframework.com/docs/theming/
// The app direction is used to include
// rtl styles in your app. For more information, please see
// https://beta.ionicframework.com/docs/layout/rtl
// $app-direction: ltr;
// Ionic Colors
// --------------------------------------------------
// Named colors makes it easy to reuse colors on various components.
// It's highly recommended to change the default colors
// to match your app's branding. Ionic provides eight layered colors
// that can be changed to theme an app. Additional colors can be
// added as well (see below). For more information, please see
// https://beta.ionicframework.com/docs/theming/advanced
// To easily create custom color palettes for your app’s UI,
// check out our color generator:
// https://beta.ionicframework.com/docs/theming/color-generator
*/
@font-face {
  font-family: "AppFont";
  src: url("./assets/Poppins-Regular.ttf");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "AppFont";
  src: url("./assets/Poppins-Bold.ttf");
  font-style: normal;
  font-weight: bold;
}

:root {
  --ion-color-angular: #ac282b;
  --ion-color-communication: #8e8d93;
  --ion-color-tooling: #fe4c52;
  --ion-color-services: #fd8b2d;
  --ion-color-design: #fed035;
  --ion-color-workshop: #69bb7b;
  --ion-color-food: #3bc7c4;
  --ion-color-documentation: #b16be3;
  --ion-color-navigation: #6600cc;

  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  --ion-color-secondary: #0cd1e8;
  --ion-color-secondary-rgb: 12, 209, 232;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0bb8cc;
  --ion-color-secondary-tint: #24d6ea;

  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

/* Additional Ionic Colors
// --------------------------------------------------
// In order to add colors to be used with Ionic components,
// the color should be added as a class with the convention `.ion-color-{COLOR}`
// where `{COLOR}` is the color to be used on the Ionic component
// and each variant is defined for the color. For more information, please see
// https://beta.ionicframework.com/docs/theming/advanced
*/

.ion-color-favorite {
  --ion-color-base: #69bb7b;
  --ion-color-base-rgb: 105, 187, 123;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #5ca56c;
  --ion-color-tint: #78c288;
}

.ion-color-twitter {
  --ion-color-base: #1da1f4;
  --ion-color-base-rgb: 29, 161, 244;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #1a8ed7;
  --ion-color-tint: #34aaf5;
}

.ion-color-google {
  --ion-color-base: #dc4a38;
  --ion-color-base-rgb: 220, 74, 56;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #c24131;
  --ion-color-tint: #e05c4c;
}

.ion-color-vimeo {
  --ion-color-base: #23b6ea;
  --ion-color-base-rgb: 35, 182, 234;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #1fa0ce;
  --ion-color-tint: #39bdec;
}

.ion-color-facebook {
  --ion-color-base: #3b5998;
  --ion-color-base-rgb: 59, 89, 152;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #344e86;
  --ion-color-tint: #4f6aa2;
}

/* Shared Variables
// --------------------------------------------------
// To customize the look and feel of this app, you can override
// the CSS variables found in Ionic's source files.
// To view all the possible Ionic variables, see:
// https://beta.ionicframework.com/docs/theming/css-variables#ionic-variables
*/

:root {
  --ion-headings-font-weight: 300;
  --ion-font-family: "AppFont";
  --ion-color-angular: #ac282b;
  --ion-color-communication: #8e8d93;
  --ion-color-tooling: #fe4c52;
  --ion-color-services: #fd8b2d;
  --ion-color-design: #fed035;
  --ion-color-workshop: #69bb7b;
  --ion-color-food: #3bc7c4;
  --ion-color-documentation: #b16be3;
  --ion-color-navigation: #6600cc;
  --ion-toolbar-background: linear-gradient(
    to right,
    var(--ion-color-blue2),
    var(--ion-color-turquoise)
  );
  --ion-toolbar-color: #fff;
  --ion-toolbar-color-activated: #fff;
  --ion-item-border-color: var(--ion-color-turquoise);
  --ion-tab-bar-background: var(--ion-color-blue2);
  --ion-tab-bar-color: #1a5776;
  --ion-tab-bar-color-selected: #fff;
  --ion-text-color: var(--ion-color-darkblue);
}

ion-toolbar.ios {
  padding-top: env(safe-area-inset-top);
}

ion-header {
  line-height: 0;
}

ion-grid ion-item h3 {
  background-color: var(--ion-color-turquoise);
  color: white;
  font-weight: bold !important;
  padding-left: 4px;
}

ion-grid ion-item ion-label {
  white-space: normal !important;
}

ion-grid ion-item p {
  padding-left: 4px;
}

ion-grid ion-thumbnail {
  width: 100% !important;
}

.list-header h3 {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 20px;
}

.text-muted {
  color: #86888f;
  /*font-size: small;*/
}

.responsive-image img {
  width: 100%;
  border-radius: 4px;
  margin-top: 15px;
}

.jc-nopadding ion-item{
  --padding-start: 0px;
  --inner-padding-end: 0px;
}

.fab-blue {
  --background: var(--ion-color-blue2);
}

.marker-cluster-small {
  background-color: #10acaa77;
}

.marker-cluster-small div {
  background-color: #10acaacc;
  color: white;
  font-weight: bold;
}

.slider-audio {
  margin-bottom: 40px;
}

.to-map-btn {
  text-align: right !important;
}

.jc-poi-number {
  border-radius: 30px;
  margin-top: 15px;
  margin-left: 19px;
  color: var(--ion-color-turquoise);
  font-weight: bold;
}

.jc-poi-number:before {
  content: unset;
}

@media (min-width: 992px) {
  ion-content > *:not(.jc-nomargin) {
    margin-left: 200px;
    margin-right: 200px;
  }
  .additional-info-col {
    margin: 20px 0 20px 0;
  }
  .responsive-video {
    height: 100%;
    background: none;
  }
  ion-slides {
    height: 100%;
  }
  .responsive-image img {
    width: auto;
    max-width: 95%;
  }
  .sticky-title {
    position: sticky;
    top: 0;
    background: white;
    z-index: 2;
    height: fit-content;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .to-map-btn {
    text-align: initial !important;
  }
}

.jc-margin-20 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

ion-modal.filter-overlay {
  --background: url(./assets/splash.jpg) center fixed no-repeat;
  --ion-background-color: none;
  --color: #fff;
}

.ios ion-modal.filter-overlay {
  --background: var(--ion-color-turquoise);
}

.filter-overlay ion-item{
  --background: #fff7;
}

.js-mapicon svg {
  width: 100%;
  height: 100%
}

.js-mapicon.has-text svg .text-icon,
.js-mapicon.has-video svg .video-icon,
.js-mapicon.has-audio svg .audio-icon,
.js-mapicon.has-kultur svg .kultur-icon {
  fill: #fff !important;
}

.tour-teile h3 {
  width: 100%;
  margin: 0.5px;
  padding: 4px 8px 4px 8px;
  background-color: var(--ion-color-turquoise);
  color: white;
  text-transform: unset;
  font-size: unset;
}

.tour-link {
  width: 100%;
  margin: 0.5px;
  padding: 4px 8px 4px 24px;
  background-color: var(--ion-color-turquoise);
  color: white;
}

.leaflet-control-attribution {
  pointer-events: none;
}